/* .modal-content{
  padding: 0px 0px 20px 0px;
}

.modal-body{
  align-items: flex-start;
} */
.btn-close{
  position: absolute;
  top: 16px;
  right: 16px;
}