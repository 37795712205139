.abc {
  background: url(../../Assets/images/arrowDown.png);
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  border: none;
  margin-top: 10px;
  opacity: 0.7;
}
.abc1 {
  background: url(../../Assets/images/arrowDown.png);
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  border: none;
  transform: rotate(0.5turn);
  margin-bottom: 10px;
  opacity: 0.7;
}
